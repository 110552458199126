html {
}
header {
  background-color: rgba(255, 255, 255, 0.05) !important;
   margin-bottom: 20px !important;
}
footer {
  background-color: rgba(255, 255, 255, 0.05) !important;
  margin-top: 20px !important;
}
.item {
  display: flex;
  text-decoration: none;
  padding: 10px;
  background: rgba(255, 255, 255, 0.05) !important;
  border-radius: 5px;
  margin-bottom: 15px;
  color: #ffffff !important;
}

.green-color {
  color: rgb(0, 197, 154) !important;
  .progress-bar {
    background-color: rgb(0, 197, 154) !important;
  }
}
.gray-color {
  color: var(--bs-gray);
  .progress-bar {
    background-color: var(--bs-gray) !important;
  }
}
.blue-color {
  color: var(--bs-blue);
  .progress-bar {
    background-color: var(--bs-blue) !important;;
  }
}
a.green-color {
  text-decoration: none;
}
@media (max-width: 840px) {
  th.nonmobile, td.nonmobile {
    display: none !important;
  }
}

.background-1 {
  background: rgba(255, 255, 255, 0.05) !important;
  color: #ffffff !important;
}
.stats-box {
  min-height: 120px;
  border-radius: 5px;
  margin-bottom: 15px;
  text-align: center;
  vertical-align: middle;
}
.main-box {
  .card-header {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .card-text {
    margin: 10px 0;
  }
}
.button-box {
  margin: 20px;
  text-align: right;
  width: 100%;
  min-width: 300px;
  padding-right: 20px;
}
.staking-content {
  background-color: rgba(255, 255, 255, 0.05) !important;
  color: #ffffff !important;

  .card-header {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.green-button, a.green-button {
  box-sizing: border-box;
  border: 1px solid rgba(0, 197, 154, 0.1) !important;
  background: rgba(0, 197, 154, 0.8) !important;
  color: rgb(245, 246, 252);
}

.green-button:hover, a.green-button:hover {
  background: rgba(0, 197, 154, 1) !important;
}
.btn-outline-green {
  --bs-btn-color: rgba(0, 197, 154, 0.8) !important;;
  --bs-btn-border-color: rgba(0, 197, 154, 0.8) !important;;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgba(0, 197, 154, 0.8);
  --bs-btn-hover-border-color: rgba(0, 197, 154, 0.8) !important;;
  --bs-btn-focus-shadow-rgb: 13,110,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgba(0, 197, 154, 0.8);
  --bs-btn-active-border-color: rgba(0, 197, 154, 0.8) !important;;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: rgba(0, 197, 154, 0.8) !important;;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: rgba(0, 197, 154, 0.8) !important;
  --bs-gradient: none;
  --bs-btn-bg: none !important;

}
.btn-outline-green:hover {
  background-color: rgba(0, 197, 154, 0.8) !important;
}

.main-box .card-header, .staking-content .card-header {
  padding: 20px;
  font-size: 20px;
}

.nav-link.active {
  background: rgba(0, 197, 154, 0.8) !important;
  border-color: rgba(0, 197, 154, 0.8) !important;
}
.nav-link {
  background: rgba(255, 255, 255, 0.05) !important;
  color: rgb(245, 246, 252) !important;

  button:hover {
    background: rgba(0, 197, 154, 0.8) !important;
    border-color: rgba(0, 197, 154, 0.8) !important;
  }
}
.nav-tabs {
  .nav-item {
    margin-right: 5px;

    button {
      min-height: 40px;
    }
  }
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  max-width: 5px;
  max-height: 5px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.15) !important;;
  border-radius: 2px;
}
.tab-content {
  max-width: 100%;
  overflow-x: scroll;


  @media (max-width: 1180px) {
    a.green-color {
      max-width: 10rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
      vertical-align: middle;
    }
  }

  @media (max-width: 480px) {
    a.green-color {
      max-width: 6rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.pagination {
  margin-bottom: 10px;
  --bs-pagination-bg: rgba(255, 255, 255, 0.05) !important;
  --bs-pagination-color: rgba(0, 197, 154, 0.8) !important;
  .page-link:hover {
    z-index: 2;
    color: rgba(0, 197, 154, 1) !important;
    background-color: rgba(255, 255, 255, 0.15) !important;
    border-color: rgba(255, 255, 255, 0.15) !important;
  }

  .page-link {
    border: none;
  }

  .active>.page-link, .page-link.active {
    background-color: rgba(0, 197, 154, 0.8) !important;
    border-color: rgba(0, 197, 154, 1) !important;
  }
  .active>.page-link, .page-link.active:hover {
    color: #ffffff !important;
  }

  .disabled>.page-link, .page-link.disabled {
    color: var(--bs-pagination-disabled-color);
    background-color: rgba(255, 255, 255, 0.25) !important;
    border-color: rgba(255, 255, 255, 0.25) !important;
  }
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  isolation: isolate;
  border-color: rgba(0, 197, 154, 0.8) !important;
}
.table>:not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state,var(--bs-table-color-type,var(--bs-table-color)));
  background-color: rgba(255, 255, 255, 0.05) !important;;
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state,var(--bs-table-bg-type,var(--bs-table-accent-bg)));
}
.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-bg-type: rgba(255, 255, 255, 0.08) !important;
}
.table-hover>tbody>tr:hover>* {
  --bs-table-bg-state: rgba(255, 255, 255, 0.15) !important;
}

.item-icon {
  margin-top: 3px;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
}

.item-icon img {
  width: 100%;
  height: 100%;
}

.item-icon.png img {
  width: 45px;
  height: 45px;
  margin: 7px;
}

.item-name {
  color: #ffffff;
  font-size: 19px;
  font-weight: bold;
}

.item-des {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  margin-top: 8px;
  font-weight: 300;
}

.item-info {
  width: calc(100% - 75px);
  padding-left: 10px;
}

.bold {
  font-weight: bold;
  color: #fff;
}
.roi {
  float: right;
  font-size: 15px;
  margin-top: 2px;
  margin-right: -5px;
}
@media (max-width: 359px) {
  .hide360px {
    display: none;
  }
}
@media (max-width: 490px) {
  .hide-490 {
    display: none !important;
  }
  .show-490 {
    display: block !important;
  }
}
@media (min-width: 489px) {
  .hide-490 {
    display: block !important;
  }
  .show-490 {
    display: none !important;
  }
}
.you-stake {
  margin-top: 5px;
}
.withdraw-waiting {
  margin-top: 5px;
}
.text-center {
  text-align: center!important;
}
.ml-3 {
  margin-left: 1rem!important;
}
a {
  text-decoration: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
  line-height: 2;
  margin-right: 0.5rem;
}
h2.heading {
  margin: 20px 0;
  font-size: 30px;
  font-weight: bolder;
}

@media (max-width: 768px) {
  .none-display-576 {
    display: none;
  }

  .special-bg:after {
    width: 200px !important;
    height: 200px !important;;
  }
  .special-bg-right:after {
    width: 200px !important;;
    height: 200px !important;;
  }
}

.special-bg:after {
  content: "";
  position: absolute;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: linear-gradient(135.15deg,#00c59a 1.17%,#00c59acc 31.88%, #00c59a33 65.46%) no-repeat;
  filter: blur(160px);
  opacity: .5;
}

.special-bg-right:after {
  content: "";
  right: 0;
  position: absolute;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: linear-gradient(135.15deg,#00c59a 1.17%,#00c59acc 31.88%, #00c59a33 65.46%) no-repeat;
  filter: blur(160px);
  opacity: .5;
}

.box-shadow {
  background: #ffffff0d!important;
  box-shadow: 0 8px 32px #0000001a;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}
.list-group {
  .list-group-item {
    background-color: transparent !important;
    border: none !important;
    color: #fff;
    line-height: 20px;
  }
}
.title-underline {
  position: relative;
  display: inline-block;
}
.title-underline:before{
    position: absolute;
    height: 5px;
    width: 80px;
    left: 0;
    bottom: -5px;
    background: rgba(0, 197, 154, 0.8);
    display: block;
    content: "";
}
.tvl-gradient {
  background: linear-gradient(90.33deg,#00c59a99 .02%,#0000001a 98.92%);
}
.width-600 {
  width: 600px;
  margin: 0 auto;
  text-align: center;
  line-height: 25px;


  @media (max-width: 768px) {
    width: 100%;
    .tvl-amount {
      margin-bottom: 2.5rem;
    }
  }
}